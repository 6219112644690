define("@ember-data/graph/-private", ["exports", "@ember/debug", "@ember-data/store/-private"], function (_exports, _debug, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.graphFor = graphFor;
  _exports.peekGraph = peekGraph;
  // that developers may provide IDs as numbers (e.g., `store.findRecord('person', 1)`),
  // it is important that internally we use strings, since IDs may be serialized
  // and lose type information.  For example, Ember's router may put a record's
  // ID into the URL, and if we later try to deserialize that URL and find the
  // corresponding record, we will not know if it is a string or a number.
  function coerceId(id) {
    if (id === null || id === undefined || id === '') {
      return null;
    }
    if (typeof id === 'string') {
      return id;
    }
    if (typeof id === 'symbol') {
      return id.toString();
    }
    return '' + id;
  }
  function getStore(wrapper) {
    (false && !('_store' in wrapper) && (0, _debug.assert)(`expected a private _store property`, '_store' in wrapper));
    return wrapper._store;
  }
  function expandingGet(cache, key1, key2) {
    let mainCache = cache[key1] = cache[key1] || Object.create(null);
    return mainCache[key2];
  }
  function expandingSet(cache, key1, key2, value) {
    let mainCache = cache[key1] = cache[key1] || Object.create(null);
    mainCache[key2] = value;
  }
  function assertValidRelationshipPayload(graph, op) {
    const relationship = graph.get(op.record, op.field);
    (false && !(isHasMany(relationship) || isBelongsTo(relationship)) && (0, _debug.assert)(`Cannot update an implicit relationship`, isHasMany(relationship) || isBelongsTo(relationship)));
    const payload = op.value;
    const {
      definition,
      identifier,
      state
    } = relationship;
    const {
      type
    } = identifier;
    const {
      field
    } = op;
    const {
      isAsync,
      kind
    } = definition;
    if (payload.links) {
      (false && (0, _debug.warn)(`You pushed a record of type '${type}' with a relationship '${field}' configured as 'async: false'. You've included a link but no primary data, this may be an error in your payload. EmberData will treat this relationship as known-to-be-empty.`, isAsync || !!payload.data || state.hasReceivedData, {
        id: 'ds.store.push-link-for-sync-relationship'
      }));
    } else if (payload.data) {
      if (kind === 'belongsTo') {
        (false && !(!Array.isArray(payload.data)) && (0, _debug.assert)(`A ${type} record was pushed into the store with the value of ${field} being ${(0, _debug.inspect)(payload.data)}, but ${field} is a belongsTo relationship so the value must not be an array. You should probably check your data payload or serializer.`, !Array.isArray(payload.data)));
        assertRelationshipData(getStore(graph.store), identifier, payload.data, definition);
      } else if (kind === 'hasMany') {
        (false && !(Array.isArray(payload.data)) && (0, _debug.assert)(`A ${type} record was pushed into the store with the value of ${field} being '${(0, _debug.inspect)(payload.data)}', but ${field} is a hasMany relationship so the value must be an array. You should probably check your data payload or serializer.`, Array.isArray(payload.data)));
        if (Array.isArray(payload.data)) {
          for (let i = 0; i < payload.data.length; i++) {
            assertRelationshipData(getStore(graph.store), identifier, payload.data[i], definition);
          }
        }
      }
    }
  }
  function isNew(identifier) {
    if (!identifier.id) {
      return true;
    }
    const cache = (0, _private.peekCache)(identifier);
    return Boolean(cache?.isNew(identifier));
  }
  function isBelongsTo(relationship) {
    return relationship.definition.kind === 'belongsTo';
  }
  function isImplicit(relationship) {
    return relationship.definition.isImplicit;
  }
  function isHasMany(relationship) {
    return relationship.definition.kind === 'hasMany';
  }
  function forAllRelatedIdentifiers(rel, cb) {
    if (isBelongsTo(rel)) {
      if (rel.remoteState) {
        cb(rel.remoteState);
      }
      if (rel.localState && rel.localState !== rel.remoteState) {
        cb(rel.localState);
      }
    } else if (isHasMany(rel)) {
      // ensure we don't walk anything twice if an entry is
      // in both localMembers and remoteMembers
      let seen = new Set();
      for (let i = 0; i < rel.localState.length; i++) {
        const inverseIdentifier = rel.localState[i];
        if (!seen.has(inverseIdentifier)) {
          seen.add(inverseIdentifier);
          cb(inverseIdentifier);
        }
      }
      for (let i = 0; i < rel.remoteState.length; i++) {
        const inverseIdentifier = rel.remoteState[i];
        if (!seen.has(inverseIdentifier)) {
          seen.add(inverseIdentifier);
          cb(inverseIdentifier);
        }
      }
    } else {
      let seen = new Set();
      rel.localMembers.forEach(inverseIdentifier => {
        if (!seen.has(inverseIdentifier)) {
          seen.add(inverseIdentifier);
          cb(inverseIdentifier);
        }
      });
      rel.remoteMembers.forEach(inverseIdentifier => {
        if (!seen.has(inverseIdentifier)) {
          seen.add(inverseIdentifier);
          cb(inverseIdentifier);
        }
      });
    }
  }

  /*
    Removes the given identifier from BOTH remote AND local state.
  
    This method is useful when either a deletion or a rollback on a new record
    needs to entirely purge itself from an inverse relationship.
    */
  function removeIdentifierCompletelyFromRelationship(graph, relationship, value, silenceNotifications) {
    if (isBelongsTo(relationship)) {
      if (relationship.remoteState === value) {
        relationship.remoteState = null;
      }
      if (relationship.localState === value) {
        relationship.localState = null;
        // This allows dematerialized inverses to be rematerialized
        // we shouldn't be notifying here though, figure out where
        // a notification was missed elsewhere.
        if (!silenceNotifications) {
          notifyChange(graph, relationship.identifier, relationship.definition.key);
        }
      }
    } else if (isHasMany(relationship)) {
      relationship.remoteMembers.delete(value);
      relationship.localMembers.delete(value);
      const canonicalIndex = relationship.remoteState.indexOf(value);
      if (canonicalIndex !== -1) {
        relationship.remoteState.splice(canonicalIndex, 1);
      }
      const currentIndex = relationship.localState.indexOf(value);
      if (currentIndex !== -1) {
        relationship.localState.splice(currentIndex, 1);
        // This allows dematerialized inverses to be rematerialized
        // we shouldn't be notifying here though, figure out where
        // a notification was missed elsewhere.
        if (!silenceNotifications) {
          notifyChange(graph, relationship.identifier, relationship.definition.key);
        }
      }
    } else {
      relationship.remoteMembers.delete(value);
      relationship.localMembers.delete(value);
    }
  }

  // TODO add silencing at the graph level
  function notifyChange(graph, identifier, key) {
    if (identifier === graph._removing) {
      return;
    }
    graph.store.notifyChange(identifier, 'relationships', key);
  }
  function assertRelationshipData(store, identifier, data, meta) {
    (false && !(!Array.isArray(data)) && (0, _debug.assert)(`A ${identifier.type} record was pushed into the store with the value of ${meta.key} being '${JSON.stringify(data)}', but ${meta.key} is a belongsTo relationship so the value must not be an array. You should probably check your data payload or serializer.`, !Array.isArray(data)));
    (false && !(data === null || typeof data.type === 'string' && data.type.length) && (0, _debug.assert)(`Encountered a relationship identifier without a type for the ${meta.kind} relationship '${meta.key}' on <${identifier.type}:${identifier.id}>, expected an identifier with type '${meta.type}' but found\n\n'${JSON.stringify(data, null, 2)}'\n\nPlease check your serializer and make sure it is serializing the relationship payload into a JSON API format.`, data === null || typeof data.type === 'string' && data.type.length));
    (false && !(data === null || !!coerceId(data.id)) && (0, _debug.assert)(`Encountered a relationship identifier without an id for the ${meta.kind} relationship '${meta.key}' on <${identifier.type}:${identifier.id}>, expected an identifier but found\n\n'${JSON.stringify(data, null, 2)}'\n\nPlease check your serializer and make sure it is serializing the relationship payload into a JSON API format.`, data === null || !!coerceId(data.id)));
    (false && !(data === null || !data.type || store.getSchemaDefinitionService().doesTypeExist(data.type)) && (0, _debug.assert)(`Encountered a relationship identifier with type '${data.type}' for the ${meta.kind} relationship '${meta.key}' on <${identifier.type}:${identifier.id}>, Expected an identifier with type '${meta.type}'. No model was found for '${data.type}'.`, data === null || !data.type || store.getSchemaDefinitionService().doesTypeExist(data.type)));
  }
  function createState() {
    return {
      hasReceivedData: false,
      isEmpty: true,
      isStale: false,
      hasFailedLoadAttempt: false,
      shouldForceReload: false,
      hasDematerializedInverse: false
    };
  }
  class BelongsToRelationship {
    constructor(definition, identifier) {
      this.definition = definition;
      this.identifier = identifier;
      this._state = null;
      this.transactionRef = 0;
      this.meta = null;
      this.links = null;
      this.localState = null;
      this.remoteState = null;
    }
    get state() {
      let {
        _state
      } = this;
      if (!_state) {
        _state = this._state = createState();
      }
      return _state;
    }
    getData() {
      let data;
      let payload = {};
      if (this.localState) {
        data = this.localState;
      }
      if (this.localState === null && this.state.hasReceivedData) {
        data = null;
      }
      if (this.links) {
        payload.links = this.links;
      }
      if (data !== undefined) {
        payload.data = data;
      }
      if (this.meta) {
        payload.meta = this.meta;
      }
      return payload;
    }
  }
  class ManyRelationship {
    constructor(definition, identifier) {
      this.definition = definition;
      this.identifier = identifier;
      this._state = null;
      this.transactionRef = 0;
      this.localMembers = new Set();
      this.remoteMembers = new Set();
      this.meta = null;
      this.links = null;

      // persisted state
      this.remoteState = [];
      // local client state
      this.localState = [];
    }
    get state() {
      let {
        _state
      } = this;
      if (!_state) {
        _state = this._state = createState();
      }
      return _state;
    }
    getData() {
      let payload = {};
      if (this.state.hasReceivedData) {
        payload.data = this.localState.slice();
      }
      if (this.links) {
        payload.links = this.links;
      }
      if (this.meta) {
        payload.meta = this.meta;
      }
      return payload;
    }
  }

  /*
    Assert that `addedRecord` has a valid type so it can be added to the
    relationship of the `record`.
  
    The assert basically checks if the `addedRecord` can be added to the
    relationship (specified via `relationshipMeta`) of the `record`.
  
    This utility should only be used internally, as both record parameters must
    be stable record identifiers and the `relationshipMeta` needs to be the meta
    information about the relationship, retrieved via
    `record.relationshipFor(key)`.
  */
  let assertPolymorphicType;
  let assertInheritedSchema;
  /**
   *
   * Given RHS (Right Hand Side)
   *
   * ```ts
   * class User extends Model {
   *   @hasMany('animal', { async: false, inverse: 'owner' }) pets;
   * }
   * ```
   *
   * Given LHS (Left Hand Side)
   *
   * ```ts
   * class Animal extends Model {
   *  @belongsTo('user', { async: false, inverse: 'pets' }) owner;
   * }
   * ```
   *
   * The UpgradedMeta for the RHS would be:
   *
   * ```ts
   * {
   *   kind: 'hasMany',
   *   key: 'pets',
   *   type: 'animal',
   *   isAsync: false,
   *   isImplicit: false,
   *   isCollection: true,
   *   isPolymorphic: false,
   *   inverseKind: 'belongsTo',
   *   inverseKey: 'owner',
   *   inverseType: 'user',
   *   inverseIsAsync: false,
   *   inverseIsImplicit: false,
   *   inverseIsCollection: false,
   *   inverseIsPolymorphic: false,
   * }
   *
   * The UpgradeMeta for the LHS would be:
   *
   * ```ts
   * {
   *   kind: 'belongsTo',
   *   key: 'owner',
   *   type: 'user',
   *   isAsync: false,
   *   isImplicit: false,
   *   isCollection: false,
   *   isPolymorphic: false,
   *   inverseKind: 'hasMany',
   *   inverseKey: 'pets',
   *   inverseType: 'animal',
   *   inverseIsAsync: false,
   *   inverseIsImplicit: false,
   *   inverseIsCollection: true,
   *   inverseIsPolymorphic: false,
   * }
   * ```
   *
   *
   * @class UpgradedMeta
   * @internal
   */

  const BOOL_LATER = null;
  const STR_LATER = '';
  const IMPLICIT_KEY_RAND = Date.now();
  function implicitKeyFor(type, key) {
    return `implicit-${type}:${key}${IMPLICIT_KEY_RAND}`;
  }
  function syncMeta(definition, inverseDefinition) {
    definition.inverseKind = inverseDefinition.kind;
    definition.inverseKey = inverseDefinition.key;
    definition.inverseType = inverseDefinition.type;
    definition.inverseIsAsync = inverseDefinition.isAsync;
    definition.inverseIsCollection = inverseDefinition.isCollection;
    definition.inverseIsPolymorphic = inverseDefinition.isPolymorphic;
    definition.inverseIsImplicit = inverseDefinition.isImplicit;
  }
  function upgradeMeta(meta) {
    let niceMeta = {};
    let options = meta.options;
    niceMeta.kind = meta.kind;
    niceMeta.key = meta.name;
    niceMeta.type = meta.type;
    (false && !(typeof options?.async === 'boolean') && (0, _debug.assert)(`Expected relationship definition to specify async`, typeof options?.async === 'boolean'));
    niceMeta.isAsync = options.async;
    niceMeta.isImplicit = false;
    niceMeta.isCollection = meta.kind === 'hasMany';
    niceMeta.isPolymorphic = options && !!options.polymorphic;
    niceMeta.inverseKey = options && options.inverse || STR_LATER;
    niceMeta.inverseType = STR_LATER;
    niceMeta.inverseIsAsync = BOOL_LATER;
    niceMeta.inverseIsImplicit = options && options.inverse === null || BOOL_LATER;
    niceMeta.inverseIsCollection = BOOL_LATER;
    return niceMeta;
  }
  function assertConfiguration(info, type, key) {}
  function isLHS(info, type, key) {
    let isSelfReferential = info.isSelfReferential;
    let isRelationship = key === info.lhs_relationshipName;
    if (isRelationship === true) {
      return isSelfReferential === true ||
      // itself
      type === info.lhs_baseModelName ||
      // base or non-polymorphic
      // if the other side is polymorphic then we need to scan our modelNames
      info.rhs_isPolymorphic && info.lhs_modelNames.indexOf(type) !== -1 // polymorphic
      ;
    }
    return false;
  }
  function upgradeDefinition(graph, identifier, propertyName, isImplicit = false) {
    const cache = graph._definitionCache;
    const storeWrapper = graph.store;
    const polymorphicLookup = graph._potentialPolymorphicTypes;
    const {
      type
    } = identifier;
    let cached = expandingGet(cache, type, propertyName);

    // CASE: We have a cached resolution (null if no relationship exists)
    if (cached !== undefined) {
      return cached;
    }
    (false && !(!isImplicit) && (0, _debug.assert)(`Expected to find relationship definition in the cache for the implicit relationship ${propertyName}`, !isImplicit));
    let relationships = storeWrapper.getSchemaDefinitionService().relationshipsDefinitionFor(identifier);
    (false && !(relationships) && (0, _debug.assert)(`Expected to have a relationship definition for ${type} but none was found.`, relationships));
    let meta = relationships[propertyName];
    if (!meta) {
      // TODO potentially we should just be permissive here since this is an implicit relationship
      // and not require the lookup table to be populated
      if (polymorphicLookup[type]) {
        const altTypes = Object.keys(polymorphicLookup[type]);
        for (let i = 0; i < altTypes.length; i++) {
          let cached = expandingGet(cache, altTypes[i], propertyName);
          if (cached) {
            expandingSet(cache, type, propertyName, cached);
            cached.rhs_modelNames.push(type);
            return cached;
          }
        }
      }

      // CASE: We don't have a relationship at all
      // we should only hit this in prod
      (false && !(meta) && (0, _debug.assert)(`Expected to find a relationship definition for ${type}.${propertyName} but none was found.`, meta));
      cache[type][propertyName] = null;
      return null;
    }
    const definition = upgradeMeta(meta);
    let inverseDefinition;
    let inverseKey;
    const inverseType = definition.type;

    // CASE: Inverse is explicitly null
    if (definition.inverseKey === null) {
      // TODO probably dont need this assertion if polymorphic
      (false && !(getStore(storeWrapper).modelFor(inverseType)) && (0, _debug.assert)(`Expected the inverse model to exist`, getStore(storeWrapper).modelFor(inverseType)));
      inverseDefinition = null;
    } else {
      inverseKey = inverseForRelationship(getStore(storeWrapper), identifier, propertyName);

      // CASE: If we are polymorphic, and we declared an inverse that is non-null
      // we must assume that the lack of inverseKey means that there is no
      // concrete type as the baseType, so we must construct and artificial
      // placeholder
      if (!inverseKey && definition.isPolymorphic && definition.inverseKey) {
        inverseDefinition = {
          kind: 'belongsTo',
          // this must be updated when we find the first belongsTo or hasMany definition that matches
          key: definition.inverseKey,
          type: type,
          isAsync: false,
          // this must be updated when we find the first belongsTo or hasMany definition that matches
          isImplicit: false,
          isCollection: false,
          // this must be updated when we find the first belongsTo or hasMany definition that matches
          isPolymorphic: false,
          isInitialized: false // tracks whether we have seen the other side at least once
        };

        // CASE: Inverse resolves to null
      } else if (!inverseKey) {
        inverseDefinition = null;
      } else {
        // CASE: We have an explicit inverse or were able to resolve one
        let inverseDefinitions = storeWrapper.getSchemaDefinitionService().relationshipsDefinitionFor({
          type: inverseType
        });
        (false && !(inverseDefinitions) && (0, _debug.assert)(`Expected to have a relationship definition for ${inverseType} but none was found.`, inverseDefinitions));
        let meta = inverseDefinitions[inverseKey];
        (false && !(meta) && (0, _debug.assert)(`Expected to find a relationship definition for ${inverseType}.${inverseKey} but none was found.`, meta));
        inverseDefinition = upgradeMeta(meta);
      }
    }

    // CASE: We have no inverse
    if (!inverseDefinition) {
      // polish off meta
      inverseKey = implicitKeyFor(type, propertyName);
      inverseDefinition = {
        kind: 'implicit',
        key: inverseKey,
        type: type,
        isAsync: false,
        isImplicit: true,
        isCollection: true,
        // with implicits any number of records could point at us
        isPolymorphic: false
      };
      syncMeta(definition, inverseDefinition);
      syncMeta(inverseDefinition, definition);
      const info = {
        lhs_key: `${type}:${propertyName}`,
        lhs_modelNames: [type],
        lhs_baseModelName: type,
        lhs_relationshipName: propertyName,
        lhs_definition: definition,
        lhs_isPolymorphic: definition.isPolymorphic,
        rhs_key: inverseDefinition.key,
        rhs_modelNames: [inverseType],
        rhs_baseModelName: inverseType,
        rhs_relationshipName: inverseDefinition.key,
        rhs_definition: inverseDefinition,
        rhs_isPolymorphic: false,
        hasInverse: false,
        isSelfReferential: type === inverseType,
        // this could be wrong if we are self-referential but also polymorphic
        isReflexive: false // we can't be reflexive if we don't define an inverse
      };
      expandingSet(cache, inverseType, inverseKey, info);
      expandingSet(cache, type, propertyName, info);
      return info;
    }

    // CASE: We do have an inverse
    const baseType = inverseDefinition.type;

    // TODO we want to assert this but this breaks all of our shoddily written tests
    /*
      if (DEBUG) {
        let inverseDoubleCheck = inverseMeta.type.inverseFor(inverseRelationshipName, store);
         assert(`The ${inverseBaseModelName}:${inverseRelationshipName} relationship declares 'inverse: null', but it was resolved as the inverse for ${baseModelName}:${relationshipName}.`, inverseDoubleCheck);
      }
    */
    // CASE: We may have already discovered the inverse for the baseModelName
    // CASE: We have already discovered the inverse
    cached = expandingGet(cache, baseType, propertyName) || expandingGet(cache, inverseType, inverseKey);
    if (cached) {
      // TODO this assert can be removed if the above assert is enabled
      (false && !(cached.hasInverse !== false) && (0, _debug.assert)(`The ${inverseType}:${inverseKey} relationship declares 'inverse: null', but it was resolved as the inverse for ${type}:${propertyName}.`, cached.hasInverse !== false));
      let isLHS = cached.lhs_baseModelName === baseType;
      let modelNames = isLHS ? cached.lhs_modelNames : cached.rhs_modelNames;
      // make this lookup easier in the future by caching the key
      modelNames.push(type);
      expandingSet(cache, type, propertyName, cached);
      return cached;
    }

    // this is our first time so polish off the metas
    syncMeta(definition, inverseDefinition);
    syncMeta(inverseDefinition, definition);
    const lhs_modelNames = [type];
    if (type !== baseType) {
      lhs_modelNames.push(baseType);
    }
    const isSelfReferential = baseType === inverseType;
    const info = {
      lhs_key: `${baseType}:${propertyName}`,
      lhs_modelNames,
      lhs_baseModelName: baseType,
      lhs_relationshipName: propertyName,
      lhs_definition: definition,
      lhs_isPolymorphic: definition.isPolymorphic,
      rhs_key: `${inverseType}:${inverseKey}`,
      rhs_modelNames: [inverseType],
      rhs_baseModelName: inverseType,
      rhs_relationshipName: inverseKey,
      rhs_definition: inverseDefinition,
      rhs_isPolymorphic: inverseDefinition.isPolymorphic,
      hasInverse: true,
      isSelfReferential,
      isReflexive: isSelfReferential && propertyName === inverseKey
    };

    // Create entries for the baseModelName as well as modelName to speed up
    //  inverse lookups
    expandingSet(cache, baseType, propertyName, info);
    expandingSet(cache, type, propertyName, info);

    // Greedily populate the inverse
    expandingSet(cache, inverseType, inverseKey, info);
    return info;
  }
  function metaIsRelationshipDefinition(meta) {
    return typeof meta._inverseKey === 'function';
  }
  function inverseForRelationship(store, identifier, key) {
    const definition = store.getSchemaDefinitionService().relationshipsDefinitionFor(identifier)[key];
    if (!definition) {
      return null;
    }
    {
      if (metaIsRelationshipDefinition(definition)) {
        const modelClass = store.modelFor(identifier.type);
        return definition._inverseKey(store, modelClass);
      }
    }
    (false && !(definition.options?.inverse === null || typeof definition.options?.inverse === 'string' && definition.options.inverse.length > 0) && (0, _debug.assert)(`Expected the relationship defintion to specify the inverse type or null.`, definition.options?.inverse === null || typeof definition.options?.inverse === 'string' && definition.options.inverse.length > 0));
    return definition.options.inverse;
  }

  /*
      case many:1
      ========
      In a bi-directional graph with Many:1 edges, adding a value
      results in up-to 3 discrete value transitions, while removing
      a value is only 2 transitions.
  
      For adding C to A
      If: A <<-> B, C <->> D is the initial state,
      and: B <->> A <<-> C, D is the final state
  
      then we would undergo the following transitions.
  
      add C to A
      remove C from D
      add A to C
  
      For removing B from A
      If: A <<-> B, C <->> D is the initial state,
      and: A, B, C <->> D is the final state
  
      then we would undergo the following transitions.
  
      remove B from A
      remove A from B
  
      case many:many
      ===========
      In a bi-directional graph with Many:Many edges, adding or
      removing a value requires only 2 value transitions.
  
      For Adding
      If: A<<->>B, C<<->>D is the initial state (double arrows representing the many side)
      And: D<<->>C<<->>A<<->>B is the final state
  
      Then we would undergo two transitions.
  
      add C to A.
      add A to C
  
      For Removing
      If: A<<->>B, C<<->>D is the initial state (double arrows representing the many side)
      And: A, B, C<<->>D is the final state
  
      Then we would undergo two transitions.
  
      remove B from A
      remove A from B
  
      case many:?
      ========
      In a uni-directional graph with Many:? edges (modeled in EmberData with `inverse:null`) with
      artificial (implicit) inverses, replacing a value results in 2 discrete value transitions.
      This is because a Many:? relationship is effectively Many:Many.
    */
  function replaceRelatedRecords(graph, op, isRemote) {
    if (isRemote) {
      replaceRelatedRecordsRemote(graph, op, isRemote);
    } else {
      replaceRelatedRecordsLocal(graph, op, isRemote);
    }
  }
  function replaceRelatedRecordsLocal(graph, op, isRemote) {
    const identifiers = op.value;
    const relationship = graph.get(op.record, op.field);
    (false && !(isHasMany(relationship)) && (0, _debug.assert)(`expected hasMany relationship`, isHasMany(relationship)));
    relationship.state.hasReceivedData = true;

    // cache existing state
    const {
      localState,
      localMembers,
      definition
    } = relationship;
    const newValues = new Set(identifiers);
    const identifiersLength = identifiers.length;
    const newState = new Array(newValues.size);
    const newMembership = new Set();

    // wipe existing state
    relationship.localMembers = newMembership;
    relationship.localState = newState;
    const {
      type
    } = relationship.definition;
    let changed = false;
    const currentLength = localState.length;
    const iterationLength = currentLength > identifiersLength ? currentLength : identifiersLength;
    const equalLength = currentLength === identifiersLength;
    for (let i = 0, j = 0; i < iterationLength; i++) {
      let adv = false;
      if (i < identifiersLength) {
        const identifier = identifiers[i];
        // skip processing if we encounter a duplicate identifier in the array
        if (!newMembership.has(identifier)) {
          if (type !== identifier.type) {
            graph.registerPolymorphicType(type, identifier.type);
          }
          newState[j] = identifier;
          adv = true;
          newMembership.add(identifier);
          if (!localMembers.has(identifier)) {
            changed = true;
            addToInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
          }
        }
      }
      if (i < currentLength) {
        const identifier = localState[i];

        // detect reordering
        if (!newMembership.has(identifier)) {
          if (equalLength && newState[i] !== identifier) {
            changed = true;
          }
          if (!newValues.has(identifier)) {
            changed = true;
            removeFromInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
          }
        }
      }
      if (adv) {
        j++;
      }
    }
    if (changed) {
      notifyChange(graph, relationship.identifier, relationship.definition.key);
    }
  }
  function replaceRelatedRecordsRemote(graph, op, isRemote) {
    const identifiers = op.value;
    const relationship = graph.get(op.record, op.field);
    (false && !(isHasMany(relationship)) && (0, _debug.assert)(`You can only '${op.op}' on a hasMany relationship. ${op.record.type}.${op.field} is a ${relationship.definition.kind}`, isHasMany(relationship)));
    if (isRemote) {
      graph._addToTransaction(relationship);
    }
    relationship.state.hasReceivedData = true;

    // cache existing state
    const {
      remoteState,
      remoteMembers,
      definition
    } = relationship;
    const newValues = new Set(identifiers);
    const identifiersLength = identifiers.length;
    const newState = new Array(newValues.size);
    const newMembership = new Set();

    // wipe existing state
    relationship.remoteMembers = newMembership;
    relationship.remoteState = newState;
    const {
      type
    } = relationship.definition;
    let changed = false;
    const canonicalLength = remoteState.length;
    const iterationLength = canonicalLength > identifiersLength ? canonicalLength : identifiersLength;
    const equalLength = canonicalLength === identifiersLength;
    for (let i = 0, j = 0; i < iterationLength; i++) {
      let adv = false;
      if (i < identifiersLength) {
        const identifier = identifiers[i];
        if (!newMembership.has(identifier)) {
          if (type !== identifier.type) {
            graph.registerPolymorphicType(type, identifier.type);
          }
          newState[j] = identifier;
          newMembership.add(identifier);
          adv = true;
          if (!remoteMembers.has(identifier)) {
            changed = true;
            addToInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
          }
        }
      }
      if (i < canonicalLength) {
        const identifier = remoteState[i];
        if (!newMembership.has(identifier)) {
          // detect reordering
          if (equalLength && newState[j] !== identifier) {
            changed = true;
          }
          if (!newValues.has(identifier)) {
            changed = true;
            removeFromInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
          }
        }
      }
      if (adv) {
        j++;
      }
    }
    if (changed) {
      flushCanonical(graph, relationship);
      /*
      replaceRelatedRecordsLocal(
        graph,
        {
          op: op.op,
          record: op.record,
          field: op.field,
          value: remoteState,
        },
        false
      );*/
    } else {
      // preserve legacy behavior we want to change but requires some sort
      // of deprecation.
      flushCanonical(graph, relationship);
    }
  }
  function addToInverse(graph, identifier, key, value, isRemote) {
    const relationship = graph.get(identifier, key);
    const {
      type
    } = relationship.definition;
    if (type !== value.type) {
      graph.registerPolymorphicType(type, value.type);
    }
    if (isBelongsTo(relationship)) {
      relationship.state.hasReceivedData = true;
      relationship.state.isEmpty = false;
      if (isRemote) {
        graph._addToTransaction(relationship);
        if (relationship.remoteState !== null) {
          removeFromInverse(graph, relationship.remoteState, relationship.definition.inverseKey, identifier, isRemote);
        }
        relationship.remoteState = value;
      }
      if (relationship.localState !== value) {
        if (!isRemote && relationship.localState) {
          removeFromInverse(graph, relationship.localState, relationship.definition.inverseKey, identifier, isRemote);
        }
        relationship.localState = value;
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      }
    } else if (isHasMany(relationship)) {
      if (isRemote) {
        if (!relationship.remoteMembers.has(value)) {
          graph._addToTransaction(relationship);
          relationship.remoteState.push(value);
          relationship.remoteMembers.add(value);
          relationship.state.hasReceivedData = true;
          flushCanonical(graph, relationship);
        }
      } else {
        if (!relationship.localMembers.has(value)) {
          relationship.localState.push(value);
          relationship.localMembers.add(value);
          relationship.state.hasReceivedData = true;
          notifyChange(graph, relationship.identifier, relationship.definition.key);
        }
      }
    } else {
      if (isRemote) {
        if (!relationship.remoteMembers.has(value)) {
          relationship.remoteMembers.add(value);
          relationship.localMembers.add(value);
        }
      } else {
        if (!relationship.localMembers.has(value)) {
          relationship.localMembers.add(value);
        }
      }
    }
  }
  function notifyInverseOfPotentialMaterialization(graph, identifier, key, value, isRemote) {
    const relationship = graph.get(identifier, key);
    if (isHasMany(relationship) && isRemote && relationship.remoteMembers.has(value)) {
      notifyChange(graph, relationship.identifier, relationship.definition.key);
    }
  }
  function removeFromInverse(graph, identifier, key, value, isRemote) {
    const relationship = graph.get(identifier, key);
    if (isBelongsTo(relationship)) {
      relationship.state.isEmpty = true;
      if (isRemote) {
        graph._addToTransaction(relationship);
        relationship.remoteState = null;
      }
      if (relationship.localState === value) {
        relationship.localState = null;
        notifyChange(graph, identifier, key);
      }
    } else if (isHasMany(relationship)) {
      if (isRemote) {
        graph._addToTransaction(relationship);
        let index = relationship.remoteState.indexOf(value);
        if (index !== -1) {
          relationship.remoteMembers.delete(value);
          relationship.remoteState.splice(index, 1);
        }
      }
      let index = relationship.localState.indexOf(value);
      if (index !== -1) {
        relationship.localMembers.delete(value);
        relationship.localState.splice(index, 1);
      }
      notifyChange(graph, relationship.identifier, relationship.definition.key);
    } else {
      if (isRemote) {
        relationship.remoteMembers.delete(value);
        relationship.localMembers.delete(value);
      } else {
        if (value && relationship.localMembers.has(value)) {
          relationship.localMembers.delete(value);
        }
      }
    }
  }
  function syncRemoteToLocal(graph, rel) {
    let toSet = rel.remoteState;
    let newIdentifiers = rel.localState.filter(identifier => isNew(identifier) && toSet.indexOf(identifier) === -1);
    let existingState = rel.localState;
    rel.localState = toSet.concat(newIdentifiers);
    let localMembers = rel.localMembers = new Set();
    rel.remoteMembers.forEach(v => localMembers.add(v));
    for (let i = 0; i < newIdentifiers.length; i++) {
      localMembers.add(newIdentifiers[i]);
    }

    // TODO always notifying fails only one test and we should probably do away with it
    if (existingState.length !== rel.localState.length) {
      notifyChange(graph, rel.identifier, rel.definition.key);
    } else {
      for (let i = 0; i < existingState.length; i++) {
        if (existingState[i] !== rel.localState[i]) {
          notifyChange(graph, rel.identifier, rel.definition.key);
          break;
        }
      }
    }
  }
  function flushCanonical(graph, rel) {
    graph._scheduleLocalSync(rel);
  }
  function addToRelatedRecords(graph, op, isRemote) {
    const {
      record,
      value,
      index
    } = op;
    const relationship = graph.get(record, op.field);
    (false && !(isHasMany(relationship)) && (0, _debug.assert)(`You can only '${op.op}' on a hasMany relationship. ${record.type}.${op.field} is a ${relationship.definition.kind}`, isHasMany(relationship)));
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        addRelatedRecord(graph, relationship, record, value[i], index !== undefined ? index + i : index, isRemote);
      }
    } else {
      addRelatedRecord(graph, relationship, record, value, index, isRemote);
    }
    notifyChange(graph, relationship.identifier, relationship.definition.key);
  }
  function addRelatedRecord(graph, relationship, record, value, index, isRemote) {
    (false && !(value) && (0, _debug.assert)(`expected an identifier to add to the relationship`, value));
    const {
      localMembers,
      localState
    } = relationship;
    if (localMembers.has(value)) {
      return;
    }
    const {
      type
    } = relationship.definition;
    if (type !== value.type) {
      graph.registerPolymorphicType(value.type, type);
    }
    relationship.state.hasReceivedData = true;
    localMembers.add(value);
    if (index === undefined) {
      localState.push(value);
    } else {
      localState.splice(index, 0, value);
    }
    addToInverse(graph, value, relationship.definition.inverseKey, record, isRemote);
  }
  function mergeIdentifier(graph, op, relationships) {
    Object.keys(relationships).forEach(key => {
      const rel = relationships[key];
      if (!rel) {
        return;
      }
      mergeIdentifierForRelationship(graph, op, rel);
    });
  }
  function mergeIdentifierForRelationship(graph, op, rel) {
    rel.identifier = op.value;
    forAllRelatedIdentifiers(rel, identifier => {
      const inverse = graph.get(identifier, rel.definition.inverseKey);
      mergeInRelationship(graph, inverse, op);
    });
  }
  function mergeInRelationship(graph, rel, op) {
    if (isBelongsTo(rel)) {
      mergeBelongsTo(graph, rel, op);
    } else if (isHasMany(rel)) {
      mergeHasMany(graph, rel, op);
    } else {
      mergeImplicit(graph, rel, op);
    }
  }
  function mergeBelongsTo(graph, rel, op) {
    if (rel.remoteState === op.record) {
      rel.remoteState = op.value;
    }
    if (rel.localState === op.record) {
      rel.localState = op.record;
      notifyChange(graph, rel.identifier, rel.definition.key);
    }
  }
  function mergeHasMany(graph, rel, op) {
    if (rel.remoteMembers.has(op.record)) {
      rel.remoteMembers.delete(op.record);
      rel.remoteMembers.add(op.value);
      const index = rel.remoteState.indexOf(op.record);
      rel.remoteState.splice(index, 1, op.value);
    }
    if (rel.localMembers.has(op.record)) {
      rel.localMembers.delete(op.record);
      rel.localMembers.add(op.value);
      const index = rel.localState.indexOf(op.record);
      rel.localState.splice(index, 1, op.value);
      notifyChange(graph, rel.identifier, rel.definition.key);
    }
  }
  function mergeImplicit(graph, rel, op) {
    if (rel.remoteMembers.has(op.record)) {
      rel.remoteMembers.delete(op.record);
      rel.remoteMembers.add(op.value);
    }
    if (rel.localMembers.has(op.record)) {
      rel.localMembers.delete(op.record);
      rel.localMembers.add(op.value);
    }
  }
  function removeFromRelatedRecords(graph, op, isRemote) {
    const {
      record,
      value
    } = op;
    const relationship = graph.get(record, op.field);
    (false && !(isHasMany(relationship)) && (0, _debug.assert)(`You can only '${op.op}' on a hasMany relationship. ${record.type}.${op.field} is a ${relationship.definition.kind}`, isHasMany(relationship)));
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        removeRelatedRecord(graph, relationship, record, value[i], isRemote);
      }
    } else {
      removeRelatedRecord(graph, relationship, record, value, isRemote);
    }
    notifyChange(graph, relationship.identifier, relationship.definition.key);
  }
  function removeRelatedRecord(graph, relationship, record, value, isRemote) {
    (false && !(value) && (0, _debug.assert)(`expected an identifier to add to the relationship`, value));
    const {
      localMembers,
      localState
    } = relationship;
    if (!localMembers.has(value)) {
      return;
    }
    localMembers.delete(value);
    let index = localState.indexOf(value);
    (false && !(index !== -1) && (0, _debug.assert)(`expected localMembers and localState to be in sync`, index !== -1));
    localState.splice(index, 1);
    removeFromInverse(graph, value, relationship.definition.inverseKey, record, isRemote);
  }
  function replaceRelatedRecord(graph, op, isRemote = false) {
    const relationship = graph.get(op.record, op.field);
    (false && !(isBelongsTo(relationship)) && (0, _debug.assert)(`You can only '${op.op}' on a belongsTo relationship. ${op.record.type}.${op.field} is a ${relationship.definition.kind}`, isBelongsTo(relationship)));
    if (isRemote) {
      graph._addToTransaction(relationship);
    }
    const {
      definition,
      state
    } = relationship;
    const prop = isRemote ? 'remoteState' : 'localState';
    const existingState = relationship[prop];

    /*
      case 1:1
      ========
      In a bi-directional graph with 1:1 edges, replacing a value
      results in up-to 4 discrete value transitions.
       If: A <-> B, C <-> D is the initial state,
      and: A <-> C, B, D is the final state
       then we would undergo the following 4 transitions.
       remove A from B
      add C to A
      remove C from D
      add A to C
       case 1:many
      ===========
      In a bi-directional graph with 1:Many edges, replacing a value
      results in up-to 3 discrete value transitions.
       If: A<->>B<<->D, C<<->D is the initial state (double arrows representing the many side)
      And: A<->>C<<->D, B<<->D is the final state
       Then we would undergo three transitions.
       remove A from B
      add C to A.
      add A to C
       case 1:?
      ========
      In a uni-directional graph with 1:? edges (modeled in EmberData with `inverse:null`) with
      artificial (implicit) inverses, replacing a value results in up-to 3 discrete value transitions.
      This is because a 1:? relationship is effectively 1:many.
       If: A->B, C->B is the initial state
      And: A->C, C->B is the final state
       Then we would undergo three transitions.
       Remove A from B
      Add C to A
      Add A to C
    */

    // nothing for us to do
    if (op.value === existingState) {
      // if we were empty before but now know we are empty this needs to be true
      state.hasReceivedData = true;
      // if this is a remote update we still sync
      if (isRemote) {
        const {
          localState
        } = relationship;
        // don't sync if localState is a new record and our remoteState is null
        if (localState && isNew(localState) && !existingState) {
          return;
        }
        if (existingState && localState === existingState) {
          notifyInverseOfPotentialMaterialization(graph, existingState, definition.inverseKey, op.record, isRemote);
        } else {
          relationship.localState = existingState;
          notifyChange(graph, relationship.identifier, relationship.definition.key);
        }
      }
      return;
    }

    // remove this value from the inverse if required
    if (existingState) {
      removeFromInverse(graph, existingState, definition.inverseKey, op.record, isRemote);
    }

    // update value to the new value
    relationship[prop] = op.value;
    state.hasReceivedData = true;
    state.isEmpty = op.value === null;
    state.isStale = false;
    state.hasFailedLoadAttempt = false;
    if (op.value) {
      if (definition.type !== op.value.type) {
        // assert(
        //   `The '<${definition.inverseType}>.${op.field}' relationship expects only '${definition.type}' records since it is not polymorphic. Received a Record of type '${op.value.type}'`,
        //   definition.isPolymorphic
        // );

        // TODO this should now handle the deprecation warning if isPolymorphic is not set
        // but the record does turn out to be polymorphic
        // this should still assert if the user is relying on legacy inheritance/mixins to
        // provide polymorphic behavior and has not yet added the polymorphic flags

        graph.registerPolymorphicType(definition.type, op.value.type);
      }
      addToInverse(graph, op.value, definition.inverseKey, op.record, isRemote);
    }
    if (isRemote) {
      const {
        localState,
        remoteState
      } = relationship;
      if (localState && isNew(localState) && !remoteState) {
        return;
      }
      if (localState !== remoteState) {
        relationship.localState = remoteState;
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      }
    } else {
      notifyChange(graph, relationship.identifier, relationship.definition.key);
    }
  }

  /*
    This method normalizes a link to an "links object". If the passed link is
    already an object it's returned without any modifications.
  
    See http://jsonapi.org/format/#document-links for more information.
  */
  function _normalizeLink(link) {
    switch (typeof link) {
      case 'object':
        return link;
      case 'string':
        return {
          href: link
        };
    }
  }

  /*
      Updates the "canonical" or "remote" state of a relationship, replacing any existing
      state and blowing away any local changes (excepting new records).
  */
  function updateRelationshipOperation(graph, op) {
    const relationship = graph.get(op.record, op.field);
    (false && !(isHasMany(relationship) || isBelongsTo(relationship)) && (0, _debug.assert)(`Cannot update an implicit relationship`, isHasMany(relationship) || isBelongsTo(relationship)));
    const {
      definition,
      state,
      identifier
    } = relationship;
    const {
      isCollection
    } = definition;
    const payload = op.value;
    let hasRelationshipDataProperty = false;
    let hasUpdatedLink = false;
    if (payload.meta) {
      relationship.meta = payload.meta;
    }
    if (payload.data !== undefined) {
      hasRelationshipDataProperty = true;
      if (isCollection) {
        // TODO deprecate this case. We
        // have tests saying we support it.
        if (payload.data === null) {
          payload.data = [];
        }
        (false && !(Array.isArray(payload.data)) && (0, _debug.assert)(`Expected an array`, Array.isArray(payload.data)));
        const cache = graph.store.identifierCache;
        // TODO may not need to cast to stable identifiers here since update likely does this too
        graph.update({
          op: 'replaceRelatedRecords',
          record: identifier,
          field: op.field,
          value: payload.data.map(i => cache.getOrCreateRecordIdentifier(i))
        }, true);
      } else {
        // TODO may not need to cast to stable identifiers here since update likely does this too
        graph.update({
          op: 'replaceRelatedRecord',
          record: identifier,
          field: op.field,
          value: payload.data ? graph.store.identifierCache.getOrCreateRecordIdentifier(payload.data) : null
        }, true);
      }
    } else if (definition.isAsync === false && !state.hasReceivedData) {
      hasRelationshipDataProperty = true;
      if (isCollection) {
        graph.update({
          op: 'replaceRelatedRecords',
          record: identifier,
          field: op.field,
          value: []
        }, true);
      } else {
        graph.update({
          op: 'replaceRelatedRecord',
          record: identifier,
          field: op.field,
          value: null
        }, true);
      }
    }
    if (payload.links) {
      let originalLinks = relationship.links;
      relationship.links = payload.links;
      if (payload.links.related) {
        let relatedLink = _normalizeLink(payload.links.related);
        let currentLink = originalLinks && originalLinks.related ? _normalizeLink(originalLinks.related) : null;
        let currentLinkHref = currentLink ? currentLink.href : null;
        if (relatedLink && relatedLink.href && relatedLink.href !== currentLinkHref) {
          (false && (0, _debug.warn)(`You pushed a record of type '${identifier.type}' with a relationship '${definition.key}' configured as 'async: false'. You've included a link but no primary data, this may be an error in your payload. EmberData will treat this relationship as known-to-be-empty.`, definition.isAsync || state.hasReceivedData, {
            id: 'ds.store.push-link-for-sync-relationship'
          }));
          (false && !(typeof relatedLink.href === 'string' || relatedLink.href === null) && (0, _debug.assert)(`You have pushed a record of type '${identifier.type}' with '${definition.key}' as a link, but the value of that link is not a string.`, typeof relatedLink.href === 'string' || relatedLink.href === null));
          hasUpdatedLink = true;
        }
      }
    }

    /*
         Data being pushed into the relationship might contain only data or links,
         or a combination of both.
          IF contains only data
         IF contains both links and data
          state.isEmpty -> true if is empty array (has-many) or is null (belongs-to)
          state.hasReceivedData -> true
          hasDematerializedInverse -> false
          state.isStale -> false
          allInverseRecordsAreLoaded -> run-check-to-determine
          IF contains only links
          state.isStale -> true
         */
    relationship.state.hasFailedLoadAttempt = false;
    if (hasRelationshipDataProperty) {
      let relationshipIsEmpty = payload.data === null || Array.isArray(payload.data) && payload.data.length === 0;

      // we don't need to notify here as the update op we pushed in above will notify once
      // membership is in the correct state.
      relationship.state.hasReceivedData = true;
      relationship.state.isStale = false;
      relationship.state.hasDematerializedInverse = false;
      relationship.state.isEmpty = relationshipIsEmpty;
    } else if (hasUpdatedLink) {
      // only notify stale if we have not previously received membership data.
      // within this same transaction
      // this prevents refetching when only one side of the relationship in the
      // payload contains the info while the other side contains just a link
      // this only works when the side with just a link is a belongsTo, as we
      // don't know if a hasMany has full information or not.
      // see #7049 for context.
      if (isCollection || !relationship.state.hasReceivedData || relationship.transactionRef === 0) {
        relationship.state.isStale = true;
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      } else {
        relationship.state.isStale = false;
      }
    }
  }
  const Graphs = new Map();

  /*
   * Graph acts as the cache for relationship data. It allows for
   * us to ask about and update relationships for a given Identifier
   * without requiring other objects for that Identifier to be
   * instantiated (such as `RecordData` or a `Record`)
   *
   * This also allows for us to make more substantive changes to relationships
   * with increasingly minor alterations to other portions of the internals
   * over time.
   *
   * The graph is made up of nodes and edges. Each unique identifier gets
   * its own node, which is a dictionary with a list of that node's edges
   * (or connections) to other nodes. In `Model` terms, a node represents a
   * record instance, with each key (an edge) in the dictionary correlating
   * to either a `hasMany` or `belongsTo` field on that record instance.
   *
   * The value for each key, or `edge` is the identifier(s) the node relates
   * to in the graph from that key.
   */
  class Graph {
    constructor(store) {
      this._definitionCache = Object.create(null);
      this._potentialPolymorphicTypes = Object.create(null);
      this.identifiers = new Map();
      this.store = store;
      this.isDestroyed = false;
      this._willSyncRemote = false;
      this._willSyncLocal = false;
      this._pushedUpdates = {
        belongsTo: [],
        hasMany: [],
        deletions: []
      };
      this._updatedRelationships = new Set();
      this._transaction = null;
      this._removing = null;
    }
    has(identifier, propertyName) {
      let relationships = this.identifiers.get(identifier);
      if (!relationships) {
        return false;
      }
      return relationships[propertyName] !== undefined;
    }
    get(identifier, propertyName) {
      (false && !(propertyName) && (0, _debug.assert)(`expected propertyName`, propertyName));
      let relationships = this.identifiers.get(identifier);
      if (!relationships) {
        relationships = Object.create(null);
        this.identifiers.set(identifier, relationships);
      }
      let relationship = relationships[propertyName];
      if (!relationship) {
        const info = upgradeDefinition(this, identifier, propertyName);
        (false && !(info !== null) && (0, _debug.assert)(`Could not determine relationship information for ${identifier.type}.${propertyName}`, info !== null)); // if (info.rhs_definition?.kind === 'implicit') {
        //   // we should possibly also do this
        //   // but it would result in being extremely permissive for other relationships by accident
        //   // this.registerPolymorphicType(info.rhs_baseModelName, identifier.type);
        // }
        const meta = isLHS(info, identifier.type, propertyName) ? info.lhs_definition : info.rhs_definition;
        if (meta.kind !== 'implicit') {
          const Klass = meta.kind === 'hasMany' ? ManyRelationship : BelongsToRelationship;
          relationship = relationships[propertyName] = new Klass(meta, identifier);
        } else {
          relationship = relationships[propertyName] = {
            definition: meta,
            identifier,
            localMembers: new Set(),
            remoteMembers: new Set()
          };
        }
      }
      return relationship;
    }

    /*
     * Allows for the graph to dynamically discover polymorphic connections
     * without needing to walk prototype chains.
     *
     * Used by edges when an added `type` does not match the expected `type`
     * for that edge.
     *
     * Currently we assert before calling this. For a public API we will want
     * to call out to the schema manager to ask if we should consider these
     * types as equivalent for a given relationship.
     */
    registerPolymorphicType(type1, type2) {
      const typeCache = this._potentialPolymorphicTypes;
      let t1 = typeCache[type1];
      if (!t1) {
        t1 = typeCache[type1] = Object.create(null);
      }
      t1[type2] = true;
      let t2 = typeCache[type2];
      if (!t2) {
        t2 = typeCache[type2] = Object.create(null);
      }
      t2[type1] = true;
    }

    /*
     TODO move this comment somewhere else
     implicit relationships are relationships which have not been declared but the inverse side exists on
     another record somewhere
      For example if there was:
      ```app/models/comment.js
     import Model, { attr } from '@ember-data/model';
      export default class Comment extends Model {
       @attr text;
     }
     ```
      and there is also:
      ```app/models/post.js
     import Model, { attr, hasMany } from '@ember-data/model';
      export default class Post extends Model {
       @attr title;
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      Then we would have a implicit 'post' relationship for the comment record in order
     to be do things like remove the comment from the post if the comment were to be deleted.
    */

    isReleasable(identifier) {
      const relationships = this.identifiers.get(identifier);
      if (!relationships) {
        return true;
      }
      const keys = Object.keys(relationships);
      for (let i = 0; i < keys.length; i++) {
        const relationship = relationships[keys[i]];
        // account for previously unloaded relationships
        // typically from a prior deletion of a record that pointed to this one implicitly
        if (relationship === undefined) {
          continue;
        }
        (false && !(relationship) && (0, _debug.assert)(`Expected a relationship`, relationship));
        if (relationship.definition.inverseIsAsync) {
          return false;
        }
      }
      return true;
    }
    unload(identifier, silenceNotifications) {
      const relationships = this.identifiers.get(identifier);
      if (relationships) {
        // cleans up the graph but retains some nodes
        // to allow for rematerialization
        Object.keys(relationships).forEach(key => {
          let rel = relationships[key];
          if (!rel) {
            return;
          }
          destroyRelationship(this, rel, silenceNotifications);
          if (isImplicit(rel)) {
            relationships[key] = undefined;
          }
        });
      }
    }
    remove(identifier) {
      (false && !(!this._removing) && (0, _debug.assert)(`Cannot remove ${String(identifier)} while still removing ${String(this._removing)}`, !this._removing));
      this._removing = identifier;
      this.unload(identifier);
      this.identifiers.delete(identifier);
      this._removing = null;
    }

    /*
     * Remote state changes
     */
    push(op) {
      if (op.op === 'deleteRecord') {
        this._pushedUpdates.deletions.push(op);
      } else if (op.op === 'replaceRelatedRecord') {
        this._pushedUpdates.belongsTo.push(op);
      } else {
        const relationship = this.get(op.record, op.field);
        (false && !(!isImplicit(relationship)) && (0, _debug.assert)(`Cannot push a remote update for an implicit relationship`, !isImplicit(relationship)));
        this._pushedUpdates[relationship.definition.kind].push(op);
      }
      if (!this._willSyncRemote) {
        this._willSyncRemote = true;
        getStore(this.store)._schedule('coalesce', () => this._flushRemoteQueue());
      }
    }

    /*
     * Local state changes
     */

    update(op, isRemote = false) {
      (false && !(op.op === 'deleteRecord' || op.op === 'mergeIdentifiers' || !isImplicit(this.get(op.record, op.field))) && (0, _debug.assert)(`Cannot update an implicit relationship`, op.op === 'deleteRecord' || op.op === 'mergeIdentifiers' || !isImplicit(this.get(op.record, op.field))));
      switch (op.op) {
        case 'mergeIdentifiers':
          {
            const relationships = this.identifiers.get(op.record);
            if (relationships) {
              mergeIdentifier(this, op, relationships);
            }
            break;
          }
        case 'updateRelationship':
          (false && !(isRemote) && (0, _debug.assert)(`Can only perform the operation updateRelationship on remote state`, isRemote));
          updateRelationshipOperation(this, op);
          break;
        case 'deleteRecord':
          {
            (false && !(isRemote) && (0, _debug.assert)(`Can only perform the operation deleteRelationship on remote state`, isRemote));
            const identifier = op.record;
            const relationships = this.identifiers.get(identifier);
            if (relationships) {
              Object.keys(relationships).forEach(key => {
                const rel = relationships[key];
                if (!rel) {
                  return;
                }
                // works together with the has check
                relationships[key] = undefined;
                removeCompletelyFromInverse(this, rel);
              });
              this.identifiers.delete(identifier);
            }
            break;
          }
        case 'replaceRelatedRecord':
          replaceRelatedRecord(this, op, isRemote);
          break;
        case 'addToRelatedRecords':
          addToRelatedRecords(this, op, isRemote);
          break;
        case 'removeFromRelatedRecords':
          removeFromRelatedRecords(this, op, isRemote);
          break;
        case 'replaceRelatedRecords':
          replaceRelatedRecords(this, op, isRemote);
          break;
        default:
          (false && !(false) && (0, _debug.assert)(`No local relationship update operation exists for '${op.op}'`));
      }
    }
    _scheduleLocalSync(relationship) {
      this._updatedRelationships.add(relationship);
      if (!this._willSyncLocal) {
        this._willSyncLocal = true;
        getStore(this.store)._schedule('sync', () => this._flushLocalQueue());
      }
    }
    _flushRemoteQueue() {
      if (!this._willSyncRemote) {
        return;
      }
      this._transaction = new Set();
      this._willSyncRemote = false;
      const {
        deletions,
        hasMany,
        belongsTo
      } = this._pushedUpdates;
      this._pushedUpdates.deletions = [];
      this._pushedUpdates.hasMany = [];
      this._pushedUpdates.belongsTo = [];
      for (let i = 0; i < deletions.length; i++) {
        this.update(deletions[i], true);
      }
      for (let i = 0; i < hasMany.length; i++) {
        this.update(hasMany[i], true);
      }
      for (let i = 0; i < belongsTo.length; i++) {
        this.update(belongsTo[i], true);
      }
      this._finalize();
    }
    _addToTransaction(relationship) {
      (false && !(this._transaction !== null) && (0, _debug.assert)(`expected a transaction`, this._transaction !== null));
      relationship.transactionRef++;
      this._transaction.add(relationship);
    }
    _finalize() {
      if (this._transaction) {
        this._transaction.forEach(v => v.transactionRef = 0);
        this._transaction = null;
      }
    }
    _flushLocalQueue() {
      if (!this._willSyncLocal) {
        return;
      }
      this._willSyncLocal = false;
      let updated = this._updatedRelationships;
      this._updatedRelationships = new Set();
      updated.forEach(rel => syncRemoteToLocal(this, rel));
    }
    destroy() {
      Graphs.delete(this.store);
      this.identifiers.clear();
      this.store = null;
      this.isDestroyed = true;
    }
  }

  // Handle dematerialization for relationship `rel`.  In all cases, notify the
  // relationship of the dematerialization: this is done so the relationship can
  // notify its inverse which needs to update state
  //
  // If the inverse is sync, unloading this record is treated as a client-side
  // delete, so we remove the inverse records from this relationship to
  // disconnect the graph.  Because it's not async, we don't need to keep around
  // the identifier as an id-wrapper for references
  function destroyRelationship(graph, rel, silenceNotifications) {
    if (isImplicit(rel)) {
      if (graph.isReleasable(rel.identifier)) {
        removeCompletelyFromInverse(graph, rel);
      }
      return;
    }
    const {
      identifier
    } = rel;
    const {
      inverseKey
    } = rel.definition;
    if (!rel.definition.inverseIsImplicit) {
      forAllRelatedIdentifiers(rel, inverseIdentifer => notifyInverseOfDematerialization(graph, inverseIdentifer, inverseKey, identifier, silenceNotifications));
    }
    if (!rel.definition.inverseIsImplicit && !rel.definition.inverseIsAsync) {
      rel.state.isStale = true;
      clearRelationship(rel);

      // necessary to clear relationships in the ui from dematerialized records
      // hasMany is managed by Model which calls `retreiveLatest` after
      // dematerializing the resource-cache instance.
      // but sync belongsTo requires this since they don't have a proxy to update.
      // so we have to notify so it will "update" to null.
      // we should discuss whether we still care about this, probably fine to just
      // leave the ui relationship populated since the record is destroyed and
      // internally we've fully cleaned up.
      if (!rel.definition.isAsync && !silenceNotifications) {
        notifyChange(graph, rel.identifier, rel.definition.key);
      }
    }
  }
  function notifyInverseOfDematerialization(graph, inverseIdentifier, inverseKey, identifier, silenceNotifications) {
    if (!graph.has(inverseIdentifier, inverseKey)) {
      return;
    }
    let relationship = graph.get(inverseIdentifier, inverseKey);
    (false && !(!isImplicit(relationship)) && (0, _debug.assert)(`expected no implicit`, !isImplicit(relationship))); // For remote members, it is possible that inverseRecordData has already been associated to
    // to another record. For such cases, do not dematerialize the inverseRecordData
    if (!isBelongsTo(relationship) || !relationship.localState || identifier === relationship.localState) {
      removeDematerializedInverse(graph, relationship, identifier, silenceNotifications);
    }
  }
  function clearRelationship(relationship) {
    if (isBelongsTo(relationship)) {
      relationship.localState = null;
      relationship.remoteState = null;
      relationship.state.hasReceivedData = false;
      relationship.state.isEmpty = true;
    } else {
      relationship.localMembers.clear();
      relationship.remoteMembers.clear();
      relationship.localState = [];
      relationship.remoteState = [];
    }
  }
  function removeDematerializedInverse(graph, relationship, inverseIdentifier, silenceNotifications) {
    if (isBelongsTo(relationship)) {
      const inverseIdentifier = relationship.localState;
      if (!relationship.definition.isAsync || inverseIdentifier && isNew(inverseIdentifier)) {
        // unloading inverse of a sync relationship is treated as a client-side
        // delete, so actually remove the models don't merely invalidate the cp
        // cache.
        // if the record being unloaded only exists on the client, we similarly
        // treat it as a client side delete
        if (relationship.localState === inverseIdentifier && inverseIdentifier !== null) {
          relationship.localState = null;
        }
        if (relationship.remoteState === inverseIdentifier && inverseIdentifier !== null) {
          relationship.remoteState = null;
          relationship.state.hasReceivedData = true;
          relationship.state.isEmpty = true;
          if (relationship.localState && !isNew(relationship.localState)) {
            relationship.localState = null;
          }
        }
      } else {
        relationship.state.hasDematerializedInverse = true;
      }
      if (!silenceNotifications) {
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      }
    } else {
      if (!relationship.definition.isAsync || inverseIdentifier && isNew(inverseIdentifier)) {
        // unloading inverse of a sync relationship is treated as a client-side
        // delete, so actually remove the models don't merely invalidate the cp
        // cache.
        // if the record being unloaded only exists on the client, we similarly
        // treat it as a client side delete
        removeIdentifierCompletelyFromRelationship(graph, relationship, inverseIdentifier);
      } else {
        relationship.state.hasDematerializedInverse = true;
      }
      if (!silenceNotifications) {
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      }
    }
  }
  function removeCompletelyFromInverse(graph, relationship) {
    const {
      identifier
    } = relationship;
    const {
      inverseKey
    } = relationship.definition;
    forAllRelatedIdentifiers(relationship, inverseIdentifier => {
      if (graph.has(inverseIdentifier, inverseKey)) {
        removeIdentifierCompletelyFromRelationship(graph, graph.get(inverseIdentifier, inverseKey), identifier);
      }
    });
    if (isBelongsTo(relationship)) {
      if (!relationship.definition.isAsync) {
        clearRelationship(relationship);
      }
      relationship.localState = null;
    } else if (isHasMany(relationship)) {
      if (!relationship.definition.isAsync) {
        clearRelationship(relationship);
        notifyChange(graph, relationship.identifier, relationship.definition.key);
      }
    } else {
      relationship.remoteMembers.clear();
      relationship.localMembers.clear();
    }
  }
  function isStore(maybeStore) {
    return maybeStore._instanceCache !== undefined;
  }
  function getWrapper(store) {
    return isStore(store) ? store._instanceCache._storeWrapper : store;
  }
  function peekGraph(store) {
    return Graphs.get(getWrapper(store));
  }
  function graphFor(store) {
    const wrapper = getWrapper(store);
    let graph = Graphs.get(wrapper);
    if (!graph) {
      graph = new Graph(wrapper);
      Graphs.set(wrapper, graph);

      // in DEBUG we attach the graph to the main store for improved debuggability
    }
    return graph;
  }
});