define("@ember-data/store/index", ["exports", "@ember-data/store/index-7883b099"], function (_exports, _index7883b) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "CacheHandler", {
    enumerable: true,
    get: function () {
      return _index7883b.C;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _index7883b.S;
    }
  });
  Object.defineProperty(_exports, "normalizeModelName", {
    enumerable: true,
    get: function () {
      return _index7883b.n;
    }
  });
  Object.defineProperty(_exports, "recordIdentifierFor", {
    enumerable: true,
    get: function () {
      return _index7883b.r;
    }
  });
  Object.defineProperty(_exports, "setIdentifierForgetMethod", {
    enumerable: true,
    get: function () {
      return _index7883b.c;
    }
  });
  Object.defineProperty(_exports, "setIdentifierGenerationMethod", {
    enumerable: true,
    get: function () {
      return _index7883b.a;
    }
  });
  Object.defineProperty(_exports, "setIdentifierResetMethod", {
    enumerable: true,
    get: function () {
      return _index7883b.d;
    }
  });
  Object.defineProperty(_exports, "setIdentifierUpdateMethod", {
    enumerable: true,
    get: function () {
      return _index7883b.b;
    }
  });
  Object.defineProperty(_exports, "storeFor", {
    enumerable: true,
    get: function () {
      return _index7883b.s;
    }
  });
});