define("ember-yeti-table/components/yeti-table/thead/row/column/component", ["exports", "@ember-decorators/component", "@ember/array", "@ember/debug", "@ember/object", "@ember/object/computed", "ember-yeti-table/-private/utils/did-change-attrs-component", "ember-yeti-table/components/yeti-table/thead/row/column/template"], function (_exports, _component, _array, _debug, _object, _computed, _didChangeAttrsComponent, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
    An important component yielded from the header or head.row component that is used to define
    a column of the table.
  
    ```hbs
    <table.header as |header|>
      <header.column @prop="firstName" as |column|>
        First name
        {{if column.isAscSorted "(sorted asc)"}}
        {{if column.isDescSorted "(sorted desc)"}}
      </header.column>
    </table.header>
    ```
  
    ```hbs
    <table.thead as |head|>
      <head.row as |row|>
        <row.column @prop="firstName" as |column|>
          First name
          {{if column.isAscSorted "(sorted asc)"}}
          {{if column.isDescSorted "(sorted desc)"}}
        </row.column>
      </head.row>
    </table.thead>
    ```
  
    @yield {object} column
    @yield {boolean} column.isSorted - `true` if column is sorted ascending or descending
    @yield {boolean} column.isAscSorted - `true` if column is sorted ascending
    @yield {boolean} column.isDescSorted - `true` if column is sorted descending
  */
  let Column = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = (0, _computed.equal)('sort', 'asc'), _dec4 = (0, _computed.equal)('sort', 'desc'), _dec5 = (0, _computed.or)('isAscSorted', 'isDescSorted'), _dec6 = (0, _object.computed)('sortSequence'), _dec(_class = _dec2(_class = (_class2 = class Column extends _didChangeAttrsComponent.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "parent", void 0);
      /**
       * An important argument that Yeti Table uses to tie this column to a certain property on
       * each row object of the original `@data` (or `@loadFunction`) that was passed in.
       *
       * This is the argument that Yeti Table uses to filter and sort the data.
       *
       * This argument also allows Yeti Table to keep itself up to date when the original
       * data changes. NOTE: If this property is a nested property (one that contains periods),
       * the table will not update when this property changes. This is due to `@each` only supporting
       * one level of properties.
       *
       * If you don't need sorting, filtering or automatic table unrolling (using the blockless
       * body component), then this property is optional.
       */
      _defineProperty(this, "prop", void 0);
      /**
       * Set to `false` to hide the entire column across all rows. Keep in mind that this property
       * won't just hide the column using css. The DOM for the column will be removed. Defaults to `true`.
       */
      _defineProperty(this, "visible", true);
      /**
       * Used to turn off sorting clicking on this column (clicks won't toggle sorting anymore).
       * Useful on avatar columns, for example, where a sorting order doesn't really make sense.
       * Defaults to the `<YetiTable>` `@sortable` argument (which in turn defaults to `true`).
       */
      _defineProperty(this, "sortable", true);
      /**
       * Optionally use an `asc` or `desc` string on this argument to turn on ascending or descending sorting
       * on this column. Useful to turn on default sortings on the table.
       */
      _defineProperty(this, "sort", null);
      /**
       * Use `@sortSequence` to customize the sequence in which the sorting order will cycle when
       * clicking on this column header. You can either pass in a comma-separated string or an array
       * of strings. Accepted values are `'asc'`, `'desc'` and `'unsorted'`. The default value is `['asc', 'desc']`
       * or whatever the global table sortSequence value is.
       */
      _defineProperty(this, "sortSequence", void 0);
      /**
       * Used to turn off filtering for this column. When `false`, Yeti Table won't look for
       * values on this column. Defaults to `true`.
       */
      _defineProperty(this, "filterable", true);
      /**
       * The column filter. If passed in, Yeti Table will search this column for rows that contain this
       * string and show those rows.
       *
       * The column definitions `@filter` argument is subtractive, meaning that it will filter out rows
       * from the subset that passes the general `@filter`.
       */
      _defineProperty(this, "filter", void 0);
      /**
       * An optional function to customize the filtering logic *on this column*. This function should return true
       * or false to either include or exclude the row on the resulting set. If this function depends
       * on a value, pass that value as the `@filterUsing` argument.
       *
       * This function will be called with two arguments:
       * - `value` - the current data cell to use for filtering
       * - `filterUsing` - the value you passed in as `@filterUsing`
       */
      _defineProperty(this, "filterFunction", void 0);
      /**
       * If you `@filterFunction` function depends on a different value (other that `@filter`)
       * to show, pass it in this argument. Yeti Table uses this argument to know when to recalculate
       * the fitlered rows.
       */
      _defineProperty(this, "filterUsing", void 0);
      /**
       * Used to add a class to all the cells in this column.
       */
      _defineProperty(this, "columnClass", void 0);
      /**
       * This property is a human-readable representation of the name of the column.
       * It defaults to the trimmed `textContent` of the `<th>` element, but can be overrided
       * by using a `@name="your custom name"` argument.
       */
      _defineProperty(this, "name", void 0);
      _defineProperty(this, "onClick", void 0);
      _initializerDefineProperty(this, "isAscSorted", _descriptor, this);
      _initializerDefineProperty(this, "isDescSorted", _descriptor2, this);
      _initializerDefineProperty(this, "isSorted", _descriptor3, this);
      _defineProperty(this, "noop", () => {});
    }
    get normalizedSortSequence() {
      let sortSequence = this.get('sortSequence');
      (false && !((0, _array.isArray)(sortSequence) || typeof sortSequence === 'string') && (0, _debug.assert)('@sortSequence must be either a comma-separated string or an array. Got `${sortSequence}.`', (0, _array.isArray)(sortSequence) || typeof sortSequence === 'string'));
      if ((0, _array.isArray)(sortSequence)) {
        return sortSequence;
      } else if (typeof sortSequence === 'string') {
        return sortSequence.split(',').map(s => s.trim());
      } else {
        return [];
      }
    }
    init() {
      super.init(...arguments);
      this.didChangeAttrsConfig = {
        attrs: ['filter', 'filterUsing', 'sort']
      };
      if (this.get('parent')) {
        this.get('parent').registerColumn(this);
      }
    }
    didChangeAttrs() {
      this.get('parent').runLoadData();
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (this.get('parent')) {
        this.get('parent').unregisterColumn(this);
      }
    }
    updateName(element) {
      if (!this.name) {
        (0, _object.set)(this, 'name', element.textContent.trim());
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isAscSorted", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isDescSorted", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isSorted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "normalizedSortSequence", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "normalizedSortSequence"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateName", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateName"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = Column;
});